define("discourse/plugins/discourse-ai/discourse/components/ai-indicator-wave", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const indicatorDots = [".", ".", "."];
  const AiIndicatorWave = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{#if @loading}}
      <span class="ai-indicator-wave">
        {{#each indicatorDots as |dot|}}
          <span class="ai-indicator-wave__dot">{{dot}}</span>
        {{/each}}
      </span>
    {{/if}}
  
  */
  {
    "id": "wcGorF6P",
    "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,1],[14,0,\"ai-indicator-wave\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[32,0]],null]],null],null,[[[1,\"        \"],[10,1],[14,0,\"ai-indicator-wave__dot\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"@loading\",\"dot\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-indicator-wave.js",
    "scope": () => [indicatorDots],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "ai-indicator-wave:AiIndicatorWave"));
  var _default = _exports.default = AiIndicatorWave;
});