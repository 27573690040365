define("discourse/plugins/discourse-ai/discourse/components/modal/thumbnail-suggestions", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/components/conditional-loading-spinner", "discourse/components/d-button", "discourse/components/d-modal", "discourse/components/d-modal-cancel", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/helpers/i18n", "discourse/plugins/discourse-ai/discourse/components/thumbnail-suggestion-item", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _conditionalLoadingSpinner, _dButton, _dModal, _dModalCancel, _ajax, _ajaxError, _i18n, _thumbnailSuggestionItem, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ThumbnailSuggestions extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "selectedImages", [_tracking.tracked], function () {
      return [];
    }))();
    #selectedImages = (() => (dt7948.i(this, "selectedImages"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "thumbnails", [_tracking.tracked], function () {
      return null;
    }))();
    #thumbnails = (() => (dt7948.i(this, "thumbnails"), void 0))();
    constructor() {
      super(...arguments);
      this.findThumbnails();
    }
    get isDisabled() {
      return this.selectedImages.length === 0;
    }
    async findThumbnails() {
      this.loading = true;
      try {
        const thumbnails = await (0, _ajax.ajax)("/discourse-ai/ai-helper/suggest", {
          method: "POST",
          data: {
            mode: this.args.model.mode,
            text: this.args.model.selectedText,
            force_default_locale: true
          }
        });
        this.thumbnails = thumbnails.thumbnails;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
      }
    }
    addSelection(selection) {
      const thumbnailMarkdown = `![${selection.original_filename}|${selection.width}x${selection.height}](${selection.short_url})`;
      this.selectedImages = [...this.selectedImages, thumbnailMarkdown];
    }
    static #_4 = (() => dt7948.n(this.prototype, "addSelection", [_object.action]))();
    removeSelection(selection) {
      const thumbnailMarkdown = `![${selection.original_filename}|${selection.width}x${selection.height}](${selection.short_url})`;
      this.selectedImages = this.selectedImages.filter(thumbnail => {
        if (thumbnail !== thumbnailMarkdown) {
          return thumbnail;
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "removeSelection", [_object.action]))();
    appendSelectedImages() {
      const imageMarkdown = "\n\n" + this.selectedImages.join("\n");
      const dEditorInput = document.querySelector(".d-editor-input");
      dEditorInput.setSelectionRange(dEditorInput.value.length, dEditorInput.value.length);
      dEditorInput.focus();
      document.execCommand("insertText", false, imageMarkdown);
      this.args.closeModal();
    }
    static #_6 = (() => dt7948.n(this.prototype, "appendSelectedImages", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          class="thumbnail-suggestions-modal"
          @title={{i18n "discourse_ai.ai_helper.thumbnail_suggestions.title"}}
          @closeModal={{@closeModal}}
        >
          <:body>
            <ConditionalLoadingSpinner @condition={{this.loading}}>
              <div class="ai-thumbnail-suggestions">
                {{#each this.thumbnails as |thumbnail|}}
                  <ThumbnailSuggestionItem
                    @thumbnail={{thumbnail}}
                    @addSelection={{this.addSelection}}
                    @removeSelection={{this.removeSelection}}
                  />
                {{/each}}
              </div>
            </ConditionalLoadingSpinner>
          </:body>
    
          <:footer>
            <DButton
              @action={{this.appendSelectedImages}}
              @label="save"
              @disabled={{this.isDisabled}}
              class="btn-primary create"
            />
            <DModalCancel @close={{@closeModal}} />
          </:footer>
        </DModal>
      
    */
    {
      "id": "Hc/0+zZc",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"thumbnail-suggestions-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"discourse_ai.ai_helper.thumbnail_suggestions.title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[8,[32,2],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"ai-thumbnail-suggestions\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"thumbnails\"]]],null]],null],null,[[[1,\"              \"],[8,[32,3],null,[[\"@thumbnail\",\"@addSelection\",\"@removeSelection\"],[[30,2],[30,0,[\"addSelection\"]],[30,0,[\"removeSelection\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]],[[[1,\"\\n        \"],[8,[32,4],[[24,0,\"btn-primary create\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"appendSelectedImages\"]],\"save\",[30,0,[\"isDisabled\"]]]],null],[1,\"\\n        \"],[8,[32,5],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\",\"thumbnail\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/thumbnail-suggestions.js",
      "scope": () => [_dModal.default, _i18n.default, _conditionalLoadingSpinner.default, _thumbnailSuggestionItem.default, _dButton.default, _dModalCancel.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ThumbnailSuggestions;
});