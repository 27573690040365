define("discourse/plugins/discourse-ai/discourse/connectors/split-new-topic-category-after/ai-category-suggestion", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-ai/discourse/components/ai-split-topic-suggester", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _aiSplitTopicSuggester, _showAiHelper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiCategorySuggestion extends _component.default {
    static shouldRender(outletArgs, helper) {
      return (0, _showAiHelper.showPostAIHelper)(outletArgs, helper);
    }
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.siteSettings.ai_embeddings_enabled}}
          <AiSplitTopicSuggester
            @selectedPosts={{@outletArgs.selectedPosts}}
            @mode="suggest_category"
            @updateAction={{@outletArgs.updateCategoryId}}
          />
        {{/if}}
      
    */
    {
      "id": "n1FDexi1",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"ai_embeddings_enabled\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@selectedPosts\",\"@mode\",\"@updateAction\"],[[30,1,[\"selectedPosts\"]],\"suggest_category\",[30,1,[\"updateCategoryId\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/split-new-topic-category-after/ai-category-suggestion.js",
      "scope": () => [_aiSplitTopicSuggester.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiCategorySuggestion;
});