define("discourse/plugins/discourse-ai/initializers/ai-artifacts", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-ai/discourse/components/ai-artifact", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _pluginApi, _aiArtifact, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeAiArtifacts(api) {
    api.decorateCookedElement((element, helper) => {
      if (!helper.renderGlimmer) {
        return;
      }
      [...element.querySelectorAll("div.ai-artifact")].forEach(artifactElement => {
        const artifactId = artifactElement.getAttribute("data-ai-artifact-id");
        const artifactVersion = artifactElement.getAttribute("data-ai-artifact-version");
        helper.renderGlimmer(artifactElement, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
        /*
          
                    <AiArtifact
                      @artifactId={{artifactId}}
                      @artifactVersion={{artifactVersion}}
                    />
                  
        */
        {
          "id": "5Gm9U5mT",
          "block": "[[[1,\"\\n            \"],[8,[32,0],null,[[\"@artifactId\",\"@artifactVersion\"],[[32,1],[32,2]]],null],[1,\"\\n          \"]],[],false,[]]",
          "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/initializers/ai-artifacts.js",
          "scope": () => [_aiArtifact.default, artifactId, artifactVersion],
          "isStrictMode": true
        }), (0, _templateOnly.default)(undefined, undefined)));
      });
    }, {
      id: "ai-artifact",
      onlyStream: true
    });
  }
  var _default = _exports.default = {
    name: "ai-artifact",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.7", initializeAiArtifacts);
    }
  };
});