define("discourse/plugins/discourse-ai/discourse/connectors/topic-list-before-category/ai-topic-gist-placement", ["exports", "@glimmer/component", "discourse/plugins/discourse-ai/discourse/components/ai-topic-gist", "@ember/component", "@ember/template-factory"], function (_exports, _component, _aiTopicGist, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiTopicGistPlacement extends _component.default {
    static shouldRender(_outletArgs, helper) {
      const settings = helper.siteSettings;
      return settings.discourse_ai_enabled && settings.ai_summarization_enabled;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <AiTopicGist @topic={{@outletArgs.topic}} />
      
    */
    {
      "id": "TNsTexkK",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/topic-list-before-category/ai-topic-gist-placement.js",
      "scope": () => [_aiTopicGist.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiTopicGistPlacement;
});