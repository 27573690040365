define("discourse/plugins/discourse-ai/discourse/components/post-menu/ai-share-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", "discourse/plugins/discourse-ai/discourse/lib/copy-conversation", "discourse/plugins/discourse-ai/discourse/components/modal/share-modal", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _aiBotHelper, _copyConversation, _shareModal, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AUTO_COPY_THRESHOLD = 4;
  class AiDebugButton extends _component.default {
    static shouldRender(args) {
      return (0, _aiBotHelper.isPostFromAiBot)(args.post, args.state.currentUser);
    }
    // TODO (glimmer-post-menu): Remove this static function and move the code into the button action after the widget code is removed
    static async shareAiResponse(post, modal, showFeedback) {
      if (post.post_number <= AUTO_COPY_THRESHOLD) {
        await (0, _copyConversation.default)(post.topic, 1, post.post_number);
        showFeedback("discourse_ai.ai_bot.conversation_shared");
      } else {
        modal.show(_shareModal.default, {
          model: post
        });
      }
    }
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    shareAiResponse() {
      this.constructor.shareAiResponse(this.args.post, this.modal, this.args.showFeedback);
    }
    static #_2 = (() => dt7948.n(this.prototype, "shareAiResponse", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="post-action-menu__share-ai"
          ...attributes
          @action={{this.shareAiResponse}}
          @icon="far-copy"
          @title="discourse_ai.ai_bot.share"
        />
      
    */
    {
      "id": "2ebt8O3X",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"post-action-menu__share-ai\"],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"shareAiResponse\"]],\"far-copy\",\"discourse_ai.ai_bot.share\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/post-menu/ai-share-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiDebugButton;
});