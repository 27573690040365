define("discourse/plugins/discourse-ai/discourse/connectors/fast-edit-footer-after/ai-edit-suggestion-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _dButton, _ajax, _ajaxError, _showAiHelper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiEditSuggestionButton extends _component.default {
    static shouldRender(outletArgs, helper) {
      return (0, _showAiHelper.showPostAIHelper)(outletArgs, helper);
    }
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "suggestion", [_tracking.tracked], function () {
      return "";
    }))();
    #suggestion = (() => (dt7948.i(this, "suggestion"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "_activeAIRequest", [_tracking.tracked], function () {
      return null;
    }))();
    #_activeAIRequest = (() => (dt7948.i(this, "_activeAIRequest"), void 0))();
    get disabled() {
      return this.loading || this.suggestion?.length > 0;
    }
    get mode() {
      return this.currentUser?.ai_helper_prompts.find(prompt => prompt.name === "proofread");
    }
    suggest() {
      this.loading = true;
      this._activeAIRequest = (0, _ajax.ajax)("/discourse-ai/ai-helper/suggest", {
        method: "POST",
        data: {
          mode: this.mode.id,
          text: this.args.outletArgs.initialValue,
          custom_prompt: ""
        }
      });
      this._activeAIRequest.then(_ref => {
        let {
          suggestions
        } = _ref;
        this.suggestion = suggestions[0].trim();
        this.args.outletArgs.updateValue(this.suggestion);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
      });
      return this._activeAIRequest;
    }
    static #_5 = (() => dt7948.n(this.prototype, "suggest", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless @outletArgs.newValue}}
          <DButton
            class="btn-small btn-ai-suggest-edit"
            @action={{this.suggest}}
            @icon="discourse-sparkles"
            @label="discourse_ai.ai_helper.fast_edit.suggest_button"
            @isLoading={{this.loading}}
            @disabled={{this.disabled}}
          />
        {{/unless}}
      
    */
    {
      "id": "x1Tr9etX",
      "block": "[[[1,\"\\n\"],[41,[51,[30,1,[\"newValue\"]]],[[[1,\"      \"],[8,[32,0],[[24,0,\"btn-small btn-ai-suggest-edit\"]],[[\"@action\",\"@icon\",\"@label\",\"@isLoading\",\"@disabled\"],[[30,0,[\"suggest\"]],\"discourse-sparkles\",\"discourse_ai.ai_helper.fast_edit.suggest_button\",[30,0,[\"loading\"]],[30,0,[\"disabled\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"unless\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/fast-edit-footer-after/ai-edit-suggestion-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiEditSuggestionButton;
});